<!-- eslint-disable vue/html-self-closing -->
<template>
  <b-card class="mb-3" header-class="px-0 pt-0">
    <div
      v-if="userInfo.role_data_preview === 'admin'"
      class="ribbon-preview bg-primary"
      :class="[item.user.role_data_preview === 'admin' ? 'bg-warning' : '']"
    >
      <p class="m-0 text-uppercase">{{ gFormatRolePreview(item.user.role_data_preview) }}</p>
    </div>
    <template #header>
      <b-card-img-lazy :src="item.image_preview" top :alt="item.name" />
    </template>
    <b-button
      v-if="item.approved_status !== 'checking'"
      variant="danger"
      size="sm"
      class="btn-delete"
      @click="deleteImage"
    >
      ลบ
    </b-button>
    <b-card-title>{{ item.name }}</b-card-title>
    <h5>รายละเอียดแบบย่อ</h5>
    <b-card-text>
      <ul>
        <li>
          สถานะรีวิว
          <b-badge :variant="csFormat.formatVariantStatusBadge(item.approved_status)">{{
            csFormat.formatStatusBadge(item.approved_status)
          }}</b-badge>
        </li>
        <li v-if="$store.getters['AuthStore/_isCanManagementRedeem']">
          รูปแบบการขาย
          <b-badge :variant="item.hide_in_store ? 'danger' : 'success'">
            {{ item.hide_in_store ? 'Redeem' : 'Redeem&Coin' }}
          </b-badge>
        </li>
        <!-- <li>
          สถานะการขาย
          <b-badge :variant="item.disable ? 'danger' : 'success'" >
            {{  item.disable ? 'ปิดการขาย' : 'เปิดการขาย' }}
          </b-badge>
        </li> -->
        <li>จำนวนรูปทั้งหมด {{ item.count_image }} รูป</li>
        <li>
          ขนาดรูป <small class="text-muted">( width x height )</small>
          <p class="mb-0">{{ dimensionImage }}</p>
        </li>
        <li v-if="userInfo.role_data_preview === 'admin'">
          <p class="m-0">ผู้สร้าง (อีเมล) : {{ item.user.email }}</p>
          <p class="m-0">
            ชื่อร้าน :
            <span v-if="item.user.role_data_preview === 'admin'">
              Ongphra
            </span>
            <span v-else>
              {{ item.user.shop_name_preview }}
            </span>
          </p>
        </li>
      </ul>
    </b-card-text>
    <div v-if="showAlertReject">
      <b-alert variant="danger" :show="showAlertReject" class="mb-1">
        <div class="alert-body">
          <feather-icon icon="InfoIcon" class="mr-50" />
          {{ item.image_timeline[0].note }}
        </div>
      </b-alert>
    </div>
    <b-button v-if="item.approved_status !== 'checking'" variant="primary" block @click="editImage">
      แก้ไข
    </b-button>
    <b-button variant="flat-secondary" block @click="seeMoreDetails">
      ดูเพิ่มเติม
    </b-button>
    <b-button
      v-if="
        $store.getters['AuthStore/_isCanManagementRedeem'] &&
          (item.approved_status === 'approved' || item.approved_status === 'approve')
      "
      variant="flat-secondary"
      block
      @click="clickCreateRedeem"
    >
      สร้างแคมเปญ Redeem
    </b-button>
    <b-button
      v-if="item.approved_status === 'approved' || item.approved_status === 'approve'"
      variant="flat-secondary"
      block
      @click="copyShareImageLink"
    >
      แชร์
    </b-button>
    <b-card-text>
      <small class="text-muted">วันที่สร้าง {{ gFormatDate(item.created_at, true) }}</small>
    </b-card-text>
    <!-- <pre>{{ JSON.stringify(item, null, 2) }}</pre> -->
    <!-- <pre>{{ JSON.stringify(mainData, null, 2) }}</pre> -->
  </b-card>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  props: {
    item: {
      type: [Object, Number],
      default: null,
      required: true,
    },
  },
  data() {
    return {
      mainData: null,
    }
  },
  computed: {
    ...mapState('AuthStore', ['userInfo']),
    dimensionImage() {
      if (!this.mainData || !this.mainData.file) return 'ไม่พบข้อมูล'

      const { naturalHeight, naturalWidth } = this.mainData.file
      const height = naturalHeight || 0
      const width = naturalWidth || 0
      return `${width} x ${height}`
    },
    showAlertReject() {
      if (this.item.image_timeline.length <= 0) return false
      if (this.item.approved_status !== 'reject') return false
      // if (this.item.image_timeline[0].status_data !== 'reject') return false

      return true
    },
  },
  created() {
    try {
      this.mainData = JSON.parse(this.item.json_data)
    } catch (error) {
      // console.log('error', error)
    }
  },
  methods: {
    seeMoreDetails() {
      this.$emit('onClickSeeMore', this.item)
    },
    deleteImage() {
      this.$emit('onClickDelete', this.item)
    },
    editImage() {
      this.$emit('onClickEdit', this.item)
    },
    async copyShareImageLink() {
      if (!this.item?.share_image_link) {
        this.gDisplayToast('คัดลอกไม่สำเร็จ', 'ไม่พบลิงค์ที่ต้องการแชร์ โปรดติดต่อแอดมิน', 'danger')
        return
      }

      this.gOpenPageLoading()

      const { share_image_link } = this.item

      const formData = new FormData()
      formData.append('urlshorten', share_image_link)

      const resp = await axios.post('https://asia1.ongphrawallpaper.com/shorten', formData).catch(() => null)
      this.gClosePageLoading()

      // eslint-disable-next-line camelcase
      let urlForShare = `${share_image_link}`

      if (resp && resp.status === 200) {
        const { data } = resp

        if (typeof data === 'string' && data.startsWith('https://ophra.me')) {
          urlForShare = data
        }
      }

      const result = await this.$copyText(urlForShare).catch(() => {})
      if (!result.text) {
        this.gDisplayToast('คัดลอกไม่สำเร็จ', 'โปรดติดต่อผู้ดูแลระบบ', 'danger')
        return
      }
      this.gDisplayToast('คัดลอกแล้ว')
    },
    clickCreateRedeem() {
      this.$router.push({ name: 'redeem-management-create', params: { mainWallpaperId: this.item.id } })
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-delete {
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 0.5rem;
}
.image-preview {
  width: 100%;
  border-radius: 0.5rem;
}
.ribbon-preview {
  position: absolute;
  top: 20px;
  left: 0;
  padding: 0.2rem 1rem 0.2rem 0.5rem;
  border-radius: 0 0.4em 0.4em 0;
  color: white;
}

.ribbon-preview::before,
.ribbon-preview::after {
  position: absolute;
  content: '';
  display: block;
}

.ribbon-preview::before {
  width: 0.4em;
  height: 100%;
  top: 0;
  left: -0.4em;
  background: inherit;
  border-radius: 0.313em 0 0;
  z-index: 1;
}

.ribbon-preview::after {
  width: 0.4em;
  height: 0.6em;
  bottom: -0.2em;
  left: -0.4em;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0.313em 0 0 0.313em;
}
</style>
