<template>
  <div>
    <h2>วอลเปเปอร์ทั้งหมด</h2>
    <b-alert variant="primary" :show="userInfo.role_data_preview === 'partner'">
      <div class="alert-body">
        <p>**ช่วงเวลาที่ 1 ของตรวจสอบรูป 08.00-11.00 แสดงขึ้น APP Ongphra 11.35-11.55</p>
        <p>***ช่วงเวลาที่ 2 ของตรวจสอบรูป 13.00-16.00 แสดงขึ้น APP Ongphra 16.35-16.55</p>
      </div>
    </b-alert>
    <br />
    <form @submit="onClickSearch">
      <b-form-group>
        <div class="d-flex flex-column flex-lg-row align-items-lg-center" style="gap: 0.5rem;">
          <label class="">ค้นหา</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="ระบุชื่อวอลเปเปอร์"
            type="text"
            class="d-inline-block"
            :disabled="loading"
          />
          <b-card v-if="cIsAdmin" no-body class="mb-0 w-100">
            <b-overlay
              :show="isFetchShopNameLoading || loading"
              spinner-small
              :opacity="$store.state.appConfig.layout.skin === 'dark' ? 0 : 0.12"
              variant="dark"
            >
              <v-select
                v-model="searchByShops"
                multiple
                :get-option-label="op => `${op.shop_name} (${op.email})`"
                :options="shopNameList"
                placeholder="เลือก ร้านค้า (อีเมล)"
              />
              <template #overlay>
                <div />
              </template>
            </b-overlay>
          </b-card>
          <b-btn
            type="submit"
            variant="primary"
            :disabled="loading"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            ค้นหา
          </b-btn>
        </div>
      </b-form-group>
    </form>
    <b-overlay :show="loading" opacity="0">
      <div class="wallpapers-container">
        <b-row cols="1" cols-sm="2" cols-lg="4">
          <b-col v-for="(item, index) in items" :key="index">
            <WallpaperItem
              :item="item"
              @onClickSeeMore="onClickSeeMore"
              @onClickDelete="onClickDelete"
              @onClickEdit="onClickEdit"
            />
            <!-- <pre>{{ JSON.stringify(item, null, 2) }}</pre> -->
          </b-col>
        </b-row>
      </div>
    </b-overlay>

    <div class="wallpapers-bottom-nav">
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalPage"
            :per-page="perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
            :disabled="loading"
            @input="onPageChange"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
          <!-- <b-pagination-nav v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number last-number /> -->
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import vSelect from 'vue-select'
import WallpaperItem from './components/WallpaperItem.vue'

export default {
  components: {
    WallpaperItem,
    vSelect,
  },
  data() {
    return {
      items: [],
      loading: false,
      searchTerm: '',
      currentPage: 1,
      perPage: 1,
      totalPage: 1,
      searchByShops: [],
      shopNameList: [],
      isFetchShopNameLoading: false,
    }
  },
  computed: {
    ...mapState('AuthStore', ['userInfo']),
    // cFilterItems() {
    //   // console.log('call', this.searchTerm)
    //   if (this.searchTerm) {
    //     return [...this.items.filter(v => `${v.name}`.includes(this.searchTerm))]
    //   }
    //   return this.items
    // },
    cIsAdmin() {
      let isAdmin = false

      isAdmin = localStorage.getItem('role_wall') === 'admin'

      if (typeof this.userInfo === 'object' && 'role_data_preview' in this.userInfo) {
        isAdmin = this.userInfo?.role_data_preview === 'admin'
      }

      return isAdmin
    },
  },
  async created() {
    this.queryItems()
    this.fetchAllShop()
  },
  methods: {
    ...mapMutations('wallpapers', ['SET_DATA_EDIT_IMAGE']),
    async queryItems(page = 1, filterByName = '', shopList = []) {
      const rootEle = document.documentElement
      rootEle.scrollTo({
        top: 0,
        behavior: 'smooth',
      })

      let isAdmin = false

      isAdmin = localStorage.getItem('role_wall') === 'admin'

      if (typeof this.userInfo === 'object' && 'role_data_preview' in this.userInfo) {
        isAdmin = this.userInfo?.role_data_preview === 'admin'
      }

      let url = 'api/partner/manage-images/fetch/index'

      if (isAdmin) {
        url = 'api/admin/admin-topic-images/v2/admin-topic-images'
      }

      this.items = []
      this.loading = true

      let resp

      if (isAdmin) {
        const request = {}
        if (shopList.length > 0) {
          request.shop_ids = shopList.map(v => v.id)
        }
        resp = await this.api
          .postV2(`${url}?page=${page}&filterType=image&filterByName=${filterByName}`, request, this)
          .catch(() => null)
      } else {
        resp = await this.api
          .getV2(`${url}?page=${page}&filterType=image&filterByName=${filterByName}`)
          .catch(() => null)
      }

      if (resp && resp?.code === 200) {
        this.perPage = resp?.data?.per_page
        this.totalPage = resp?.data?.total
        // this.currentPage = resp?.current_page
        this.items = [...resp?.data?.data]
      } else {
        this.gDisplayToast('ไม่สามารถเข้าถึงข้อมูลได้', 'โปรดติดต่อแอดมิน', 'danger')
      }
      this.loading = false
    },
    async fetchAllShop() {
      if (!this.cIsAdmin) return
      this.isFetchShopNameLoading = true
      const resp = await this.api.getV2('api/admin/partners/fetch-all-shop-name').catch(() => null)
      this.isFetchShopNameLoading = false
      if (resp?.code === 200) {
        this.shopNameList = [...resp?.data]
      }
    },
    onPageChange(value) {
      this.queryItems(value, this.searchTerm, this.searchByShops)
    },
    onClickSearch(e) {
      e.preventDefault()

      if (this.currentPage > 1) {
        this.currentPage = 1
      } else {
        this.queryItems(1, this.searchTerm, this.searchByShops)
      }
    },
    onClickSeeMore(item) {
      // console.log('onClickSeeMore', item)
      this.$router.push({ name: 'wallpapers-details', params: { id: item?.id || 0 } })
    },
    async onClickDelete(item) {
      let url = 'api/partner/manage-images/image-data/destroy'

      const { role_data_preview } = this.userInfo

      // eslint-disable-next-line camelcase
      if (role_data_preview === 'admin') {
        url = '/api/admin/admin-topic-images'
      }

      const resp = await this.api.deleteV2(`${url}/${item.id}`, this)
      // console.log('onClickDelete', resp)
      if (!resp) return

      if (resp.message) {
        this.gDisplayToast('พบข้อผิดพลาด', resp.message, 'danger')
        await this.queryItems()
        return
      }
      //   const newList = [...this.items].filter(v => v.id !== item.id)
      //   this.items = JSON.parse(JSON.stringify(newList))
      this.queryItems(1, this.searchTerm)
    },
    async onClickEdit(item) {
      this.gOpenPageLoading()
      const resp = await this.api.getV2(`api/image-list-data/show/${item.id}`)
      this.gClosePageLoading()
      if (resp && resp?.code === 200) {
        const newItem = { ...item, topic_image_list_datas: [...resp?.data] }
        this.SET_DATA_EDIT_IMAGE({ data: newItem })
      } else {
        this.gDisplayToast('ไม่สามารถแก้ไขรูปได้', 'โปรดรีเฟรชหน้าจอ หรือติดต่อแอดมิน', 'danger')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wallpapers-container {
  padding-bottom: 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}
.wallpapers-bottom-nav {
  //   background-color: red;
  position: fixed;
  right: 2vw;
  bottom: -1vh;
}
</style>
